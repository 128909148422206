<template>
  <dashboard-content>
    <template v-slot:actions>
      <quota-usage
        v-if="currentHeadlineContentPack"
        :current="currentHeadlineContentPack.used"
        :limit="currentHeadlineContentPack.limit"
        :title="$t('sentence.used_credit')"
      />
    </template>

    <div class="mb-4">
      <h2>{{ $t('page.content_bot.page-title') }}</h2>
      <p>{{ $t('page.content_bot.page-subtitle') }}</p>
    </div>

    <base-navbar-tabs
      :items="tabOptions"
      v-model:active="activeTab"
    />

    <div v-if="activeTab === 'form'">
      <automated-content-form />
    </div>

    <div v-if="activeTab === 'historic'">
      <available-automated-contents />
    </div>
  </dashboard-content>
</template>

<script lang="ts">
import DashboardContent from '@/components/dashboard/DashboardContent.vue'
import { computed, defineComponent, ref } from 'vue'
import { useContentPack } from '@/composables/contentPack/useContentPack'
import QuotaUsage from '@/components/posts/QuotaUsage.vue'
import BaseNavbarTabs from '@/components/base/BaseNavbarTabs.vue'
import AvailableAutomatedContents from '@/components/posts/content-generator/AvailableAutomatedContents.vue'
import AutomatedContentForm from '@/components/posts/content-generator/AutomatedContentForm.vue'

export default defineComponent({
  components: { DashboardContent, QuotaUsage, BaseNavbarTabs, AvailableAutomatedContents, AutomatedContentForm },

  setup () {
    const activeTab = ref('form')

    const {
      currentHeadlineContentPack
    } = useContentPack()

    const tabOptions = computed(() => [
      { code: 'form', label: 'page.posts.content_generator_title', icon: 'fas fa-robot' },
      { code: 'historic', label: 'sentence.usage_historic', icon: 'fas fa-calendar-check' }
    ])

    return {
      currentHeadlineContentPack,
      tabOptions,
      activeTab
    }
  }
})
</script>

<style scoped>
  button i {
    line-height: 1.1rem;
  }
</style>