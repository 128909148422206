
import { defineComponent } from 'vue'
import { useAutomatedContent } from '@/composables/contentGeneration/useAutomatedContent'
import AutomatedContentItem from './AutomatedContentItem.vue'

export default defineComponent({
  components: { AutomatedContentItem },

  setup () {
    const {
      isLoaded,
      available,
      remove,
    } = useAutomatedContent()

    return {
      isLoaded,
      available,
      remove
    }
  }
})
