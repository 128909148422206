
import DashboardContent from '@/components/dashboard/DashboardContent.vue'
import { computed, defineComponent, ref } from 'vue'
import { useContentPack } from '@/composables/contentPack/useContentPack'
import QuotaUsage from '@/components/posts/QuotaUsage.vue'
import BaseNavbarTabs from '@/components/base/BaseNavbarTabs.vue'
import AvailableAutomatedContents from '@/components/posts/content-generator/AvailableAutomatedContents.vue'
import AutomatedContentForm from '@/components/posts/content-generator/AutomatedContentForm.vue'

export default defineComponent({
  components: { DashboardContent, QuotaUsage, BaseNavbarTabs, AvailableAutomatedContents, AutomatedContentForm },

  setup () {
    const activeTab = ref('form')

    const {
      currentHeadlineContentPack
    } = useContentPack()

    const tabOptions = computed(() => [
      { code: 'form', label: 'page.posts.content_generator_title', icon: 'fas fa-robot' },
      { code: 'historic', label: 'sentence.usage_historic', icon: 'fas fa-calendar-check' }
    ])

    return {
      currentHeadlineContentPack,
      tabOptions,
      activeTab
    }
  }
})
